(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbRespBlockLib"), require("sbInternalMsgBus"), require("mobxUtils"), require("mobxReact"), require("ReactDOM"), require("gsap"), require("sbDataLayer"), require("ReactTransitionGroup"), require("sbJsExtends"));
	else if(typeof define === 'function' && define.amd)
		define("sb-player-props", ["React", "mobx", "sbRespBlockLib", "sbInternalMsgBus", "mobxUtils", "mobxReact", "ReactDOM", "gsap", "sbDataLayer", "ReactTransitionGroup", "sbJsExtends"], factory);
	else if(typeof exports === 'object')
		exports["sb-player-props"] = factory(require("React"), require("mobx"), require("sbRespBlockLib"), require("sbInternalMsgBus"), require("mobxUtils"), require("mobxReact"), require("ReactDOM"), require("gsap"), require("sbDataLayer"), require("ReactTransitionGroup"), require("sbJsExtends"));
	else
		root["sb-player-props"] = factory(root["React"], root["mobx"], root["sbRespBlockLib"], root["sbInternalMsgBus"], root["mobxUtils"], root["mobxReact"], root["ReactDOM"], root["gsap"], root["sbDataLayer"], root["ReactTransitionGroup"], root["sbJsExtends"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__63__, __WEBPACK_EXTERNAL_MODULE__120__, __WEBPACK_EXTERNAL_MODULE__121__) {
return 